export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const GET_PROFILE_INFO = 'LOGIN/GET_PROFILE_INFO'
export const GET_PROFILE_INFO_SUCCESS = 'LOGIN/GET_PROFILE_INFO_SUCCESS'
export const GET_PROFILE_INFO_ERROR = 'LOGIN/GET_PROFILE_INFO_ERROR'

export const CLEAR_LOGIN_MESSAGE = 'CLEAR_LOGIN_MESSAGE'
