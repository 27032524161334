import { call, put, takeLatest, select } from 'redux-saga/effects'
import request from 'utils/request'
import decode from 'jwt-decode'

import { LOGIN } from './constants'
import { loginSuccess, loginError, getProfileInfoSuccess, getProfileInfoError } from './actions'

import { getGlobalData } from './selectors'

function* login({ payload }) {
  try {
    const loginCredentials = {
      userNameOrEmailAddress: payload.username,
      password: payload.password,
      reCaptchaToken: null,
      tenancyName: 'Default',
    }
    const response = yield call(request, '/api/TokenAuth/Authenticate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginCredentials),
    })
    if (response.success) {
      const token = response.result.accessToken
      localStorage.setItem('token', token)
      const roleUrl = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      const role = decode(token)[roleUrl]

      if (role === 'Admin') {
        // yield call(recieveAdminInfo)
        // yield call(recieveCountryListData)

        yield* getProfileData(token)
        yield put(loginSuccess(response))

        yield put(window.location.replace('/'))
      }
    }
  } catch (error) {
    const errorObj = yield error.response.json()
    yield put(loginError(errorObj.error.message))
  }
}

export function* getProfileData(token) {
  // call for profile

  try {
    const response = yield call(request, '/api/admin/profile', {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })

    localStorage.setItem('profile', JSON.stringify(response.result))
    yield put(getProfileInfoSuccess(response.result))
    yield put(loginSuccess({ token }))
  } catch (error) {
    yield put(getProfileInfoError(error))
  }
}

export function* uploadFile() {
  try {
    const { fileUploadData } = yield select(getGlobalData)
    const token = localStorage.getItem('token')
    const response = yield call(request, '/upload-file', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: fileUploadData,
    })
    return response.url
  } catch (error) {
    return 'failed'
  }
}

export default function* mySaga() {
  yield takeLatest(LOGIN, login)
  // yield takeLatest(GET_PROFILE_INFO, getProfileData);
}
