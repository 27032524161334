import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  GET_PROFILE_INFO,
  GET_PROFILE_INFO_SUCCESS,
  GET_PROFILE_INFO_ERROR,
  CLEAR_LOGIN_MESSAGE,
} from './constants'

export const login = (payload) => ({ type: LOGIN, payload })
export const loginSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload })
export const loginError = (error) => ({ type: LOGIN_ERROR, error })

export const logout = () => ({ type: LOGOUT })
export const logoutSuccess = (payload) => ({ type: LOGOUT_SUCCESS, payload })

export const getProfileInfo = () => ({ type: GET_PROFILE_INFO })

export const getProfileInfoSuccess = (payload) => ({ type: GET_PROFILE_INFO_SUCCESS, payload })

export const getProfileInfoError = (error) => ({ type: GET_PROFILE_INFO_ERROR, error })

export const clearLoginMessage = () => ({ type: CLEAR_LOGIN_MESSAGE })
